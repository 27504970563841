// var exp_mail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
// var exp_psw = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/;
first_time = true;
resizeWindow();
$(window).on("resize", resizeWindow);
function resizeWindow() {
    var inner_height_class = (window.innerHeight > 750 ? "" : "hg750");
    $("div.ctn-lg-ct").addClass(inner_height_class);
    $(".lg-mask-top").addClass(inner_height_class);
    $(".lg-mask-bot").addClass(inner_height_class);
}
$(".delete").on("submit", function () {
    return confirm($(this).data('confirm'));
});
$(document).on("click", ".toggle-password", function () {
    $(this).toggleClass('fa-eye fa-eye-slash');
    let input = $($(this).attr('toggle'));
    if (input.attr('type') == 'password') {
        input.attr('type', 'text');
    }
    else {
        input.attr('type', 'password');
    }
});
$(document).on("click", ".ctn-nav-ppal.active", function (e) {
    if (e.target !== this)
        return;

    closeNav();
});
$(document).on("click", ".close-menu", function () {
    closeNav();
});
$(document).on("click", ".ppal-nav.active.nb", function (e) {
    if (e.target !== this)
        return;

    closeNav();
});
function closeNav() {
    $(".ppal-nav").removeClass("active nb");
    $(".ctn-nav-ppal").removeClass("active");
    $("body").removeClass("with-menu");
    // show return button in level page and question page
    $(".bk-div.sm-hidden").fadeIn("slow");
}
$(document).on("click", ".burguer-btn", function () {
    $(".ppal-nav").addClass("active");
    $(".ctn-nav-ppal").addClass("active");
    $("body").addClass("with-menu");
    // hide return button in level page and question page
    $(".bk-div.sm-hidden").fadeOut("slow");
});
$(document).on("click", ".start-btn", function () {
    $(".without_records").each(function () {
        $(this).removeClass("without_records");
    });
    $("#levels").addClass("has-records");
    scrollLevel($(this).data('level'));
});
$(document).on("click", ".btn-go", function () {
    scrollLevel($(this).data('level'));
});
function scrollLevel(a) {
    $('html,body')
        .animate({ scrollTop: $(a).offset().top - 100 }, 1000)
        .promise()
        .then(function () {

        })
}
$(document).on("click", ".checkbox-mask", function () {
    $($(this).data('input')).click();
});
$(document).on("click", ".radio-mask", function () {
    $($(this).data('input')).click();
});
$(document).on("change", "input[type=checkbox]", function () {
    if (this.checked) {
        $($(this).data('mask')).addClass('checked')
    } else {
        $($(this).data('mask')).removeClass('checked')
    }
    showBtn($(this));
});
$(document).on("change", "input[type=radio]", function () {
    var inputs = document.querySelectorAll("input");
    for (let i = 0, c = inputs.length; i < c; i++) {
        if (inputs[i].checked) {
            $(inputs[i].getAttribute("data-mask")).addClass('checked')
        } else {
            $(inputs[i].getAttribute("data-mask")).removeClass('checked')
        }
    }
    showBtn($(this));
});
function showBtn(current_btn) {
    var inputs = document.querySelectorAll("input");
    for (let i = 0, c = inputs.length; i < c; i++) {
        if (inputs[i].checked == true) {
            $(".aw").addClass('active');
            return;
        }
    }
    $(".aw").removeClass('active');
}
$("input").focusin(function () {
    $(this).addClass("active");
    $(this).parent().find("label").addClass("active");
});
$("input").focusout(function () {
    if ($(this).val() == "") {
        $(this).removeClass("active");
        $(this).parent().find("label").removeClass("active");
    }
});
function validateInput(a, e) {
    var tString = a.val();
    if (tString != '') {
        a.next().addClass("active");
        if (e.test(tString)) {
            a.removeClass("invalid");
            a.addClass("valid");
        }
        else {
            a.removeClass("valid");
            a.addClass("invalid");
        }
    } else {
        if (first_time) {
            a.removeClass("invalid");
            a.removeClass("valid");
        }
    }
}
if (document.getElementById("btn-minka-login")) {
    // Remove error once there is a change in either email or password input
    document.getElementById("email").addEventListener("input", function (event) {
        event.target.classList.remove("invalid");
        document.getElementById("psw").classList.remove("invalid");
    });
    document.getElementById("psw").addEventListener("input", function (event) {
        document.getElementById("email").classList.remove("invalid");
        event.target.classList.remove("invalid");
    });
    document.getElementById("btn-minka-login").addEventListener("click", function () {
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
        var tzInput = document.getElementsByName("tz")[0];
        var emailInput = document.getElementById("email");
        var passwordInput = document.getElementById("psw");
        if (emailInput.value.length > 0 && passwordInput.value.length > 0) {
            $.ajax({
                url: '/login',
                type: 'POST',
                data: {
                    _token: CSRF_TOKEN,
                    tz: tzInput.value,
                    email: emailInput.value,
                    password: passwordInput.value
                },
                dataType: 'JSON',
                success: function (data) {
                    window.location.href = data;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    emailInput.classList.add("invalid");
                    passwordInput.classList.add("invalid");
                }
            });
        }
    });
}
$('body').on('keyup', function (e) {
    if (e.which == 13) {
        $(".btn-bs-lg").click();
    }
});
//$('#carouselTuto').carousel({
  //  interval: false
//});
$('#tuto_modal').on('show.bs.modal', function (e) {
    $('.close-menu').click();
});
/*
 |--------------------------------------------------------------------------
 | PASSWORD - For password reset page and page of setting
 |--------------------------------------------------------------------------
 */
// Global function check of two entered password match
function validateConfirmPsw(a) {
    a.next().addClass("active");
    if (a.val() == '') {
        a.removeClass("invalid");
        a.removeClass("valid");
        a.next().removeClass("active");
    } else {
        if (a.val() == $("#password_new").val()) {
            a.removeClass("invalid");
            a.addClass("valid");
        } else {
            a.removeClass("valid");
            a.addClass("invalid");
        }
    }
}
// Global function to make sure that the new password meets the basic requirements
function validateNewPsw(a, e) {
    var tString = a.val();
    var ul = document.querySelectorAll('.li-psw');
    if (tString.length > 7) {
        ul[0].classList.add("active");
    } else {
        ul[0].classList.remove("active");
    }
    if (/.{0,}[A-Z]{1,}.{0,}/.test(tString)) {
        ul[1].classList.add("active");
    } else {
        ul[1].classList.remove("active");
    }
    if (/.{0,}[0-9]{1,}.{0,}/.test(tString)) {
        ul[2].classList.add("active");
    } else {
        ul[2].classList.remove("active");
    }
    validateConfirmPsw($("#password_confirm"));
}
$("#password_new").change(function () {
    validateNewPsw($(this), exp_psw);
});
$("#password_new").keyup(function () {
    validateNewPsw($(this), exp_psw);
});
$("#password_confirm").keyup(function () {
    validateConfirmPsw($(this));
});
$("#password_confirm").change(function () {
    validateConfirmPsw($(this));
});
